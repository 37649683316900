import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
// Icons
import { IoDiamond, IoLogoEuro, IoHammer } from 'react-icons/io5';
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";


const About = () => {

    return (
        <Layout>
            <Seo
                title="Über uns - BIKESAFE"
                description="Bei uns erhalten Sie alles rund ums Fahrradparken aus einer Hand. Wir gestalten viele der Produkte, die wir verkaufen, selbst und bieten für alle einen umfassenden Installationsservice."
            />
            <Container>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h1>Über uns</h1>
                        <h3>Bei uns erhalten Sie alles rund ums Fahrradparken aus einer Hand.</h3>
                        <StaticImage placeholder="blurred" src="../images/about-header.jpg" className="mt-4 mb-4 shadow fluid rounded" alt="Bikesafe Fabrication" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h2 className="float-lg-left subheading-product">Unsere Ziele :</h2>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Wir gestalten viele der Produkte, die wir verkaufen, selbst und bieten für alle einen umfassenden Installationsservice.
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Wir bieten einen kompletten Service für unsere Produktreihe – von praktischen Fahrradanlehnbügeln bis hin zu Fahrradunterständen und Parksystemen für zuhause.</li>
                            <li className="border p-2 shade-on-hover">Wir sind der marktführende Hersteller in diesem Bereich und einer der offiziellen Lieferanten der Londoner Verkehrsbehörde TFL. Weiterhin beliefern wir unsere Produkte an Unternehmen, Bildungseinrichtungen, Bauunternehmer, lokale Behörden und Privatanwender.</li>
                            <li className="border p-2 shade-on-hover">Unser Ziel ist die Herstellung von Produkten mit bewährter Qualität und innovativem Design, wobei wir stets den Kundenservice im Auge behalten.</li>
                            <li className="border p-2 shade-on-hover">Wir sind das erstes Unternehmen, das Produkte rund ums Fahrrad aus recyceltem Stahl herstellt.</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>Entdecken Sie hier, wie unsere Fahrradständer in unserer Fabrik im Groβbritannien hergestellt werden:</h3>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred" src="../images/factory/1.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred" src="../images/factory/2.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred" src="../images/factory/3.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnOne" className="d-none d-lg-block" lg="2" />
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred" src="../images/factory/4.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred" src="../images/factory/5.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnTwo" className="d-none d-lg-block" lg="2" />
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>
                            Alle unsere Produkte haben die gleichen wesentlichen Eigenschaften, die für uns grundlegend für sämtliche Elemente des Stadtmobiliars sind:
                        </h3>
                        <p className="flow-text mt-5"><IoDiamond /> Hohe Qualität</p>
                        <p className="flow-text mt-5"><IoHammer /> Keine oder minimale Wartung</p>
                        <p className="flow-text mt-5"><IoLogoEuro /> Großartiges Preis-Leistungs-Verhältnis</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}

export default About;